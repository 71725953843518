import NewCarouselComponent from '@components/newCarouselComponent';
import { Section } from '@components/Section';
import { SectionWrapper } from '@components/SectionWrapper';
import { HomeFeedInterface, VideoComponentInterface } from '@customTypes/index';
import HomeFeedComponent from '@layouts/home/components/HomeFeedComponent';
import { getHomeLayoutSSR } from '@layouts/home/homeLayoutSSR';
import { getSourceEventProperties } from '@layouts/stream/helpers';
// import {
//   getBannerAdspotKey,
//   // closeBannerAdFunc,
//   insertBannerINSTag,
//   placementIds,
// } from '@modules/ads/setUpBannerAds'
import { Amplitude } from '@modules/analytics';
import organisation from '@seo/organisation.json';
import { useAppStore } from '@stores/appStore';
import { GetServerSideProps } from 'next';
import { useEffect } from 'react';
import Seo from 'src/seo/Seo';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
interface HomePageProps {
  homeFeed: HomeFeedInterface;
  carousalVideos: VideoComponent[];
  bannerVideos: VideoComponent[];
}
interface VideoComponent {
  component: VideoComponentInterface;
}
const Index = ({
  homeFeed,
  carousalVideos,
  bannerVideos
}: HomePageProps): JSX.Element => {
  const {
    setPageInfo,
    sourceTrackingInfo,
    setSourceTrackingInfo,
    setPrevSourceTrackingInfo
  } = useAppStore(state => state);
  useEffect(() => {
    const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
    Amplitude.logEvent('visit_web_home', {
      web_page_type: 'webpage_home',
      ...sourceEventProperties
    });
    setPageInfo({
      page: 'webpage_home'
    });
    setPrevSourceTrackingInfo(sourceTrackingInfo);
    setSourceTrackingInfo({
      sourcePageName: 'home'
    });
  }, []);
  const sectionHighlighted = homeFeed?.results.findIndex(ele => ele.highlightColor);
  const {
    title
  } = homeFeed?.results[sectionHighlighted - 1] ?? {};
  return _jsxs(_Fragment, {
    children: [_jsx(Seo, {
      title: "Loco | Free Online Gaming, Live-streaming & Esports Platform",
      description: "Watch top esports tournaments and gaming creators playing and live streaming all your favorite games like Free Fire, PUBG, BGMI, Valorant, GTA V and many more!",
      canonical: "https://loco.gg",
      openGraph: {
        title: 'Free Online Gaming, Live-streaming & Esports Platform | Loco',
        description: 'Watch top streamers playing and live streaming all your favorite games like Free Fire, CODM, BGMI, Valorant, GTA V and many more!',
        url: 'https://loco.gg'
      },
      robotsProps: {},
      additionalMetaTags: [{
        name: 'keywords',
        content: 'free online livestreaming, esports, livestreamimng, loco'
      }],
      data: organisation
    }), _jsxs(SectionWrapper, {
      children: [_jsx(Section, {
        title: "carousel",
        subTitleLink: "/live-streams",
        children: carousalVideos?.length ? _jsx(NewCarouselComponent, {
          videos: carousalVideos,
          banners: bannerVideos
        }) : null
      }), _jsx(HomeFeedComponent, {
        homeFeed: homeFeed?.results || [],
        sectionBeforeHighlighted: title
      })]
    })]
  });
};
Index.layoutOptions = {
  showProgressBar: true
};
export const getServerSideProps: GetServerSideProps = getHomeLayoutSSR();
export default Index;